import React from 'react';
import { Link, Home, Complete, Twitter, Wallet, Campaign, View } from '../../pages';
import { Routes, Route, Navigate } from 'react-router-dom';
import { TwitterSignIn } from '../TwitterSignIn';

export const Container = ({ twitterHandle, setTwitterHandle, walletAddr, setWalletAddr}) => {
  return (
    <div className='container'>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/link" element={<Link />} />
        <Route path="/complete" element={<Complete twitterHandle={twitterHandle} walletAddr={walletAddr} setTwitterHandle={setTwitterHandle} setWalletAddr={setWalletAddr}/>} />
        <Route path="/twitter-sign-in" element={<TwitterSignIn setTwitterHandle={setTwitterHandle} />} />
        <Route path="/wallet" element={<Wallet twitterHandle={twitterHandle} setTwitterHandle={setTwitterHandle} setWalletAddr={setWalletAddr} />} />
        <Route path="/twitter" element={<Twitter setTwitterHandle={setTwitterHandle} />} />
        <Route path="/campaign" element={<Campaign />} />
        <Route path="/view" element={<View setWalletAddr={setWalletAddr} walletAddr={walletAddr}/>} />
        <Route path="/" element={<Navigate to="/home" />} />
      </Routes>
    </div>
  );
};
