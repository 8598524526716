import React, { useEffect } from 'react';
import { ActionButton } from '../../components/ActionButton/component';
import { useState } from 'react';
import './styles.css';

export function View({ setWalletAddr, walletAddr }) {

    const [links, setLinks] = useState({});

    const handleVerifyAddress = async () => {
        // Check if Metamask is installed
        if (typeof window.ethereum === 'undefined') {
        alert('Please install Metamask to continue');
        return;
        }

        // Request account access
        try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const userAddress = accounts[0];

        // Create the message to sign
        const message = `I am proving ownership of this wallet for Token Automation Systems.`;

        // Sign the message
        const signature = await window.ethereum.request({ method: 'personal_sign', params: [message, userAddress] });

        // TODO: Do something with the signature and userAddress (e.g., send them to your server)
        console.log('User address:', userAddress);
        console.log('Signature:', signature);
        setWalletAddr(userAddress);

        } catch (error) {
            console.error('Error while connecting to Metamask:', error);
        }
    };

    const queryBackEnd = async (userAddress) => {
        const base64 = require('base-64');

        const linkUrl = process.env.REACT_APP_BACKEND_URL + '/links/lookup/address/';
        const username = process.env.REACT_APP_BASIC_AUTH_LINKER_USERNAME;
        const password = process.env.REACT_APP_BASIC_AUTH_LINKER_PASS;

        const headers = new Headers();

        headers.set(
        'Authorization',
        'Basic ' + base64.encode(username + ':' + password),
        );
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

        const body = JSON.stringify({
            address: userAddress
        });

        console.log(body);

        try {
            const response = await fetch(linkUrl, {
                method: 'POST',
                headers: headers,
                body: body,
            });
            const res = await response.json();
            console.log('response json', res);
            setLinks(res);
            return res;
        } catch (e) {
            console.log(e);
        }

    };

    const deleteLink = async (userAddress, twitterHandle) => {
        const base64 = require('base-64');

        const linkUrl = process.env.REACT_APP_BACKEND_URL + '/links/delete/';
        const username = process.env.REACT_APP_BASIC_AUTH_LINKER_USERNAME;
        const password = process.env.REACT_APP_BASIC_AUTH_LINKER_PASS;

        const headers = new Headers();
        const networkId = window.ethereum.networkVersion;

        headers.set(
        'Authorization',
        'Basic ' + base64.encode(username + ':' + password),
        );
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

        const body = JSON.stringify({
            handle: twitterHandle,
            address: userAddress
        });
        console.log(body);
        try {
            const response = await fetch(linkUrl, {
                method: 'POST',
                headers: headers,
                body: body,
            });
            const res = await response.json();
            console.log('response json', res);

            const newLinks = { ...links };
            delete newLinks[userAddress];
            setLinks(newLinks);

            return res;
        } catch (e) {
            console.log(e);
        }
    };

     useEffect(() => {
         queryBackEnd(walletAddr);
     }, [walletAddr]);
    
    return (
    <>
        <div className="descriptionBox">
            Manage Links
        </div>
        <div className="descriptionBox2">
            <p>
                Sign a message with your wallet to view your Twitter handle and EVM address link. You can also delete any links from here if you would like.
            </p>
        </div>
        <div>
            <ActionButton onClick={handleVerifyAddress} buttonText={'> Verify Address'} />
            <br />
            <br />
            <br />
            <div className="linksContainer">
                {links && Object.entries(links).length > 0 && <div>Links:</div>}
                <br />
                <br />
                {links && Object.entries(links).map(([link, value]) => (
                    <div key={link}>
                        <div>{link} : {value}</div>
                        <br />
                        <div><ActionButton onClick={() => {deleteLink(link, value)}} buttonText={'> Delete Link'} /> </div>
                        <br />
                    </div>
                ))}
                {links && Object.entries(links).length === 0 && <div>No links found</div>}
            </div>
        </div>
    </>
    );
  };