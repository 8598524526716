import React, { useEffect, useState } from 'react';
import './styles.css';
import { Button } from 'react-bootstrap';
import { checkIfWalletIsConnected, connectWallet } from '../../lweb3';

export const ConnectWalletButton = ({ setIsConnected }) => {
  const [isLoading, setLoading] = useState(false);
  const [connected, setConnected] = useState(false);

  const onClick = async () => {
    setLoading(true);
    await connectWallet();
    const conn = await checkIfWalletIsConnected();
    setIsConnected(conn);
    setConnected(conn);
  };

  useEffect(() => {
    async function fetchData() {
      const conn = await checkIfWalletIsConnected();
      setIsConnected(conn);
      setConnected(conn);
    }
    fetchData();
  });

  return (
    <Button
      variant='outline-secondary'
      bsPrefix='connect-wallet-button'
      disabled={isLoading || connected}
      onClick={onClick}
    >
      {!connected ? 'Connect Wallet' : 'Connected'}
    </Button>
  );
};
