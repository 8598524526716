
import { utils, ethers } from 'ethers';

function loadContract(address, signer) {
  const abi = require('./abi/ERC20.json');
  const contract = new ethers.Contract(address, abi.abi, signer);
  return contract;
}

const setup = async () => {
    try {
      const { ethereum } = window;
  
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const addr = await checkIfWalletIsConnected();
        const signer = provider.getSigner(addr);
  
        return [provider, signer];
      } else {
        console.log('Metamask not connected.');
      }
    } catch (error) {
      console.log(error);
    }
  };
  
export const checkBalances = async (address) => {
    try {
      const [provider, signer] = await setup();
      const contract = loadContract(address, signer);
      const walletAddress = await signer.getAddress();
      return convertFromSCNumber(await contract.balanceOf(walletAddress), contract);
    } catch (error) {
      console.log(error);
    }
};

export const transferTokens = async (address, tokenAmount, wallet) => {
    const [provider, signer] = await setup();
    const contract = loadContract(address, signer);
    const tx = await contract.connect(signer).transfer(wallet, convertToSCNumber(tokenAmount, contract));
    const res = await tx.wait();
    console.log('transfer', res);
}

// Returns account if any connected, else returns ""
export const checkIfWalletIsConnected = async () => {
    let account = '';
    try {
      const { ethereum } = window;
  
      if (!ethereum) {
        console.log('Make sure you have metamask!');
      }
  
      const accounts = await ethereum.request({ method: 'eth_accounts' });
      const network_id = window.ethereum.networkVersion;
  
      if (accounts.length !== 0) {
        account = accounts[0];
      } else {
        console.log('No authorized account found');
      }
    } catch (error) {
      console.log(error);
    }
    return account;
  };

  export const connectWallet = async () => {
    let account = '';
    try {
      const { ethereum } = window;
  
      if (!ethereum) {
        alert('Please install MetaMask!');
      }
  
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      const network_id = window.ethereum.networkVersion;
  
      console.log('network_id: ', network_id);
      console.log('Connected', accounts[0]);
      account = accounts[0];
    } catch (error) {
      console.log(error);
    }
    return account;
  };


const convertToSCNumber = async (num, contract) => {
    const decimals = await contract.decimals();
    const ret = utils.parseUnits(String(num), decimals);
    return ret;
};
  
const convertFromSCNumber = async (num, contract) => {
    const decimals = await contract.decimals();
    const intNum = parseInt(utils.formatUnits(num, 0));
    return parseFloat(intNum / 10 ** decimals);
};



// async function getAllTokensInWallet(walletAddress) {
//     // Connect to an Ethereum provider
//     const web3Provider = new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID');
//     const web3 = new Web3(web3Provider);
  
//     // Token contract ABI
//     const tokenABI = [
//       // Provide the ABI of the ERC20 token contract
//     ];
  
//     // Create a new instance of the token contract
//     const tokenContract = new web3.eth.Contract(tokenABI);
  
//     // Get the number of tokens held by the wallet address
//     const balance = await tokenContract.methods.balanceOf(walletAddress).call();
  
//     // Fetch token details for each token
//     const tokens = [];
  
//     for (let i = 0; i < balance; i++) {
//       const tokenAddress = await tokenContract.methods.tokenOfOwnerByIndex(walletAddress, i).call();
//       const tokenSymbol = await tokenContract.methods.symbol().call();
//       const tokenName = await tokenContract.methods.name().call();
  
//       tokens.push({ address: tokenAddress, symbol: tokenSymbol, name: tokenName });
//     }
  
//     return tokens;
//   }