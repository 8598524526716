import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import './styles.css';
import {transferTokens} from '../../lweb3';

export const TransferButton = ({ tokenAddress, mapping, disabled, setDisabled }) => {

  const [buttonText, setButtonText] = useState('Transfer');
  console.log('mapping', mapping);

  const onClick = async () => {
    setDisabled(true);
    setButtonText('Transferring...');
    try {
      for (const walletAddress of Object.keys(mapping)) {
        const tokenAmt = mapping[walletAddress];
        await transferTokens(tokenAddress, tokenAmt, walletAddress);
      }
    } catch (err) {
      if (err.code === 4001) {
        console.log('Transaction was not completed because it was rejected.');
      } else {
        console.error('An error occurred: ', err.message);
      }
    } finally {
      setDisabled(false);
      setButtonText('Transfer');
    }
  };  
  

  return (
    <Button className='transferButton' onClick={onClick} disabled={disabled}>{buttonText}</Button>
  );
};
