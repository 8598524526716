import React, { useState, useEffect } from 'react';
import './styles.css';
import { Button } from 'react-bootstrap';
import { BulkTokenTransfer } from '../BulkTokenTransfer';

export const InteractionLookup = ({ url, likeTokenAmount, retweetTokenAmount}) => {
  const [loadingInteractionLookup, setLoadingInteractionLookup] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [handleComponents, setHandleComponents] = useState([]);

  const [likeHandles, setLikeHandles] = useState([]);
  const [likeWalletMap, setLikeWalletMap] = useState({});


  const [retweetHandles, setRetweetHandles] = useState([]);
  const [retweetWalletMap, setRetweetWalletMap] = useState({});
  
  //takes in a url and returns a list of twitter handles that liked and retweeted the tweet
  async function lookupInteraction() {
    setLoadingInteractionLookup(true);

    const base64 = require('base-64');

    const likesLookupUrl = process.env.REACT_APP_BACKEND_URL + '/likes_lookup/handles/';
    const retweetsLookupUrl = process.env.REACT_APP_BACKEND_URL + '/retweet_lookup/handles/';
    const quoteLookupUrl = process.env.REACT_APP_BACKEND_URL + '/quote_lookup/handles/';

    const username = process.env.REACT_APP_BASIC_AUTH_LINKER_USERNAME;
    const password = process.env.REACT_APP_BASIC_AUTH_LINKER_PASS;

    const headers = new Headers();

    headers.set(
      'Authorization',
      'Basic ' + base64.encode(username + ':' + password),
    );
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    const body = JSON.stringify({
        tweet_url: url,
    });

    try {
        const likesResponse = await fetch(likesLookupUrl, {
            method: 'POST',
            headers: headers,
            body: body,
        });
        const likesRet = await likesResponse.json();
        console.log("likesRet", likesRet);

        if(likesRet['success']) {
          setLikeHandles(likesRet['data']);
        } else {
          setError(true);
          setErrorText(likesRet['data']);
          setLikeHandles([]);
          setLikeWalletMap({});
        }

        const retweetsResponse = await fetch(retweetsLookupUrl, {
            method: 'POST',
            headers: headers,
            body: body,
        });
        const retweetsRet = await retweetsResponse.json();
        console.log("retweetsRet", retweetsRet);

        if(retweetsRet['success']) {
          setRetweetHandles(retweetsRet['data']);
          setError(false);
          setErrorText('');
        } else {
          setError(true);
          setErrorText(retweetsRet['data']);
          setRetweetHandles([]);
          setRetweetWalletMap({});
        }

        const quotesResponse = await fetch(quoteLookupUrl, {
          method: 'POST',
          headers: headers,
          body: body,
        });
        const quotesRet = await quotesResponse.json();
        console.log("quotesRet", quotesRet);

        if(quotesRet['success']) {
          setRetweetHandles(prevHandles => [...prevHandles, ...quotesRet['data']]);
          setError(false);
          setErrorText('');
        } else {
          setError(true);
          setErrorText(quotesRet['data']);
        }
    } catch (e) {
        console.log(e);
    }

    setLoadingInteractionLookup(false);

  }

  //takes in a list of handles and returns a mapping of handles to wallet addresses
  async function lookupWallets(handlesToLookup, mappingFunction) {
    const base64 = require('base-64');
    const lookupUrl = process.env.REACT_APP_BACKEND_URL + '/links/lookup/handles/';
    const username = process.env.REACT_APP_BASIC_AUTH_LINKER_USERNAME;
    const password = process.env.REACT_APP_BASIC_AUTH_LINKER_PASS;

    const headers = new Headers();

    headers.set(
      'Authorization',
      'Basic ' + base64.encode(username + ':' + password),
    );
    headers.append('Accept', 'application/json');
    headers.append('Content', 'application/json');
    
    //const networkId = window.ethereum.networkVersion;

    const body = JSON.stringify({
        handles: handlesToLookup,
        //chain: networkId,
    });

    if (mappingFunction === setLikeWalletMap) {
      try {
          const response = await fetch(lookupUrl, {
              method: 'POST',
              headers: headers,
              body: body,
          });
          const ret = await response.json();
          setLikeWalletMap(ret || {});
          console.log(ret);
      } catch (e) {
          console.log(e);
          setLikeWalletMap({});
      }
    } else if (mappingFunction === setRetweetWalletMap) {
      try {
        const response = await fetch(lookupUrl, {
            method: 'POST',
            headers: headers,
            body: body,
        });
        const ret = await response.json();
        setRetweetWalletMap(ret || {});
        console.log(ret);
      } catch (e) {
          console.log(e);
          setRetweetWalletMap({});
      }
    }
  }

  //creates components displaying mapping of handles to wallet addresses
  const genHandleComponents = () => {
    const likeComponents = likeHandles.map((handle, index) => {
      if(handle in likeWalletMap) {
        const handleText = handle + ' : Linked Account'
        return (<div key={'like' + index}>{handleText}</div>)
      } else {
        return (<div key={'like' + index}>{handle}</div>)
      }
    });

    const retweetComponents = retweetHandles.map((handle, index) => {
      if(handle in retweetWalletMap) {
        const handleText = handle + ' : Linked Account'
        return (<div key={'retweet' + index}>{handleText}</div>)
      } else {
        return (<div key={'retweet' + index}>{handle}</div>)
      }
    });

    return [...likeComponents, ...retweetComponents]
  }


  useEffect(() => { 
    if (error) {
      const component = (<div>{errorText}</div>);
      setHandleComponents([component]);
    } else {
      const components = genHandleComponents();
      setHandleComponents(components);
    }
  }, [likeHandles, retweetHandles, likeWalletMap, retweetWalletMap, error, errorText]);

  //clears the states when the url is cleared
  useEffect(() => {
    if (url === '') {
      // clear the state
      setLikeHandles([]);
      setLikeWalletMap({});
      setRetweetHandles([]);
      setRetweetWalletMap({});
      setError(false);
      setErrorText('');
      setHandleComponents([]);
      // add more states if necessary
    }
  }, [url]);

  //calls lookupWallets when the handles are updated
  useEffect(() => {
    if (likeHandles.length > 0) {
      lookupWallets(likeHandles, setLikeWalletMap);
    }
  }, [likeHandles]);
  
  //calls lookupWallets when the handles are updated
  useEffect(() => {
    if (retweetHandles.length > 0) {
      lookupWallets(retweetHandles, setRetweetWalletMap);
    }
  }, [retweetHandles]);

  useEffect(() => {
    console.log(likeWalletMap); // Check the contents of likeWalletMap after it's supposed to have been updated
    console.log(retweetWalletMap); // Check the contents of retweetWalletMap after it's supposed to have been updated
  }, [likeWalletMap, retweetWalletMap]);

  return (
    <div className='interaction-lookup-master-container'>

      <Button
          variant='outline-secondary'
          bsPrefix='lookup-button'
          disabled={url === '' || loadingInteractionLookup}
          onClick={lookupInteraction}
        >
          Lookup
      </Button>

      {handleComponents}

      <hr className="section-divider" />

      {
        likeWalletMap && retweetWalletMap ? (
          <BulkTokenTransfer 
          likes={likeWalletMap} 
          retweets={retweetWalletMap} 
          likeAmount={likeTokenAmount} 
          retweetAmount={retweetTokenAmount} 
          />
        ) : null
      }

    </div>
    
  );
};