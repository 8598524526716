import React, { useEffect } from 'react';
import { ActionButton } from '../../components/ActionButton/component';
import { LinkButton } from '../../components/LinkButton/component';
import { useNavigate } from 'react-router-dom';
import { useClerk } from '@clerk/clerk-react';
import { useUser } from "@clerk/clerk-react";

export function Wallet({ twitterHandle, setTwitterHandle, setWalletAddr }) {
    
    const navigate = useNavigate();
    const { isLoaded, isSignedIn, user } = useUser();
    const { signOut } = useClerk();

    useEffect(() => {
        if (isLoaded && isSignedIn) {
          // Extract Twitter handle from the externalAccounts property
          const twitterHandleFromClerk = user.username;
      
          setTwitterHandle(twitterHandleFromClerk);
      
          // Sign out the user
          signOut().then(() => {
            console.log('User has been signed out.');
          });
        }
      }, [isLoaded, isSignedIn, user, signOut, setTwitterHandle]);

    const handleVerifyAddress = async () => {
        // Check if Metamask is installed
        if (typeof window.ethereum === 'undefined') {
        alert('Please install Metamask to continue');
        return;
        }

        // Request account access
        try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const userAddress = accounts[0];

        // Create the message to sign
        const message = `I am proving ownership of this wallet for Token Automation Sutdios.`;

        // Sign the message
        const signature = await window.ethereum.request({ method: 'personal_sign', params: [message, userAddress] });

        // TODO: Do something with the signature and userAddress (e.g., send them to your server)
        console.log('User address:', userAddress);
        console.log('Signature:', signature);
        setWalletAddr(userAddress);
        
        await pushLinkToBackEnd(userAddress, twitterHandle)

        navigate('/complete'); // Move to the next page

        } catch (error) {
        console.error('Error while connecting to Metamask:', error);
        }
    };

    const pushLinkToBackEnd = async (userAddress, twitterHandle) => {
        const base64 = require('base-64');

        const linkUrl = process.env.REACT_APP_BACKEND_URL + '/links/create/';
        const username = process.env.REACT_APP_BASIC_AUTH_LINKER_USERNAME;
        const password = process.env.REACT_APP_BASIC_AUTH_LINKER_PASS;

        const headers = new Headers();
        const networkId = window.ethereum.networkVersion;

        headers.set(
        'Authorization',
        'Basic ' + base64.encode(username + ':' + password),
        );
        headers.append('Accept', 'application/json');
        headers.append('Content', 'application/json');

        const body = JSON.stringify({
            wallet_addr: userAddress, 
            handle: twitterHandle, 
            wallet_chain: networkId, 
            link_type: 'twitter',
        });
        console.log(body);
        try {
            const response = await fetch(linkUrl, {
                method: 'POST',
                headers: headers,
                body: body,
            });
            console.log('response json', await response.json());
        
        } catch (e) {
            console.log(e);
        }
    };
    
    return (
    <>
        <div className="descriptionBox">
            Step 1, done!
        </div>
        <div className="descriptionBox2">
            <p>
                Looks like your Twitter handle is: {twitterHandle}
            </p>
            <br />
            <p>
                If this isn’t the Twitter handle you meant to link please exit the process and try again. 
                Be sure to log in to the Twitter account you want to link to your Ethereum address.
            </p>
            <br />
            <p>
                Otherwise, let’s get your Ethereum address! Clicking the button below will pop up a Metamask pop-up asking you to sign a transaction.
                Please make sure you have MetaMask installed! Wihout it, you won't be able to complete the process.
            </p>
        </div>
        <div className="navigation">
            <ActionButton onClick={handleVerifyAddress} buttonText={'> Verify Address'} />
            <LinkButton url="https://punia.notion.site/Token-Automation-Info-3eaa2b01496c4194bfd56b20d22bb92e" buttonText="> Learn More" />
        </div>
    </>
    );
  };