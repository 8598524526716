import React from 'react';
import { ActionButton } from '../../components/ActionButton/component';
import { LinkButton } from '../../components/LinkButton/component';
import { useNavigate } from 'react-router-dom';

export function Complete({twitterHandle, walletAddr, setTwitterHandle, setWalletAddr}) {

  const navigate = useNavigate();

  const clearState = () => {
    setTwitterHandle('');
    setWalletAddr('');

    console.log('Twitter and Wallet state cleared');
    navigate('/home');
  };

  return (
    <>
    <div className="descriptionBox">
      Anddd we’re all done!!
    </div><div className="descriptionBox2">
        <p>
          Your wallet {walletAddr} is now linked to your Twitter account {twitterHandle}!
        </p>
        <br />
        <p>
          Now, whenever you interact with a Tweet that has a token or NFT promotion leveraging us, you’ll see them in your wallet after interacting with the tweet!
        </p>
      </div>
      <div className="navigation">
        <ActionButton onClick={() => {clearState()}} buttonText={'> Home'} />
        <LinkButton url="https://punia.notion.site/Token-Automation-Info-3eaa2b01496c4194bfd56b20d22bb92e" buttonText="> Learn More" />
      </div>
    </>
  );
};
