import React from 'react';
import './styles.css';

export const LinkButton = ({ url, buttonText }) => {
    const navigateTo = () => {
        window.open(url, '_blank');
      };
  
  return (
    <button className="linkButton" onClick={navigateTo}>{buttonText}</button>
  );
};
