import React from 'react';
import './App.css';
import { Container } from './components/Container';
import { useState } from 'react';
import { ClerkProvider } from '@clerk/clerk-react';
import { BrowserRouter as Router} from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  return <span onClick={() => navigate("/home")}> Token Automation Systems </span>;
}

const App = () => {
  const [walletAddr, setWalletAddr] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <Router>
        <div className="App">
          <div className="mainContainer">
            <div className="gridWrapper">
              <div className="dataContainer">
                <div className="header">
                  <Header /> 
                </div>
                <Container 
                  walletAddr={walletAddr}
                  setWalletAddr={setWalletAddr}
                  twitterHandle={twitterHandle}
                  setTwitterHandle={setTwitterHandle}
                />
              </div>
              <div className="verticalLine"></div>
            </div>
          </div>
        </div>
      </Router>        
    </ClerkProvider>
  );
};

export default App;
