import React from 'react';
import { ActionButton } from '../../components/ActionButton/component';
import { LinkButton } from '../../components/LinkButton/component';
import { useNavigate } from 'react-router-dom';


export function Twitter({ setTwitterHandle }) {
    const navigate = useNavigate();

    const handleTwitterLogIn = () => {
        navigate('/twitter-sign-in');
    }

    return (
    <>
        <div className="descriptionBox">
            Let’s Get Started!
        </div>
        <div className="descriptionBox2">
            <p>
                Our process will consist of two steps and will take no more than three minutes:
            </p>
            <br />
            <p>
                1. Verify your Twitter handle.
            </p>
            <p>
                2. Verify your Ethereum public address.
            </p>
        </div>
        <div className="navigation">
            <ActionButton onClick={() => handleTwitterLogIn()} buttonText={'> Verify Twitter'} />
            <LinkButton url="https://punia.notion.site/Token-Automation-Info-3eaa2b01496c4194bfd56b20d22bb92e" buttonText="> Learn More" />
        </div>
    </>
    );
  };