import React, { useState, useEffect } from 'react';
import { Input } from '../Input';
import { ConnectWalletButton } from '../ConnectWalletButton/component';
import { TransferButton } from '../TransferButton/component';
import { checkBalances, checkIfWalletIsConnected } from '../../lweb3';

export const BulkTokenTransfer = ({likes, retweets, likeAmount, retweetAmount}) => {
  const [wallet, setWallet] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [tokenAddress, setTokenAddress] = useState('');
  const [balance, setBalance] = useState(0);
  const [tokenInput, setTokenInput] = useState(false);
  const [transferDisabled, setTransferDisabled] = useState(true);

  const [combinedMappings, setCombinedMappings] = useState({});

  console.log('Rendering BulkTokenTransfer', likes, retweets, likeAmount, retweetAmount, typeof likeAmount, typeof retweetAmount);

  /**Takes in two mappings of handles to wallet addresses and returns a mapping of handles to wallet addresses with the combined token amounts due to them.
   * For example, anyone who likes is due the likeAmount and anyone who retweets is due the retweetAmount. Anyone who does both is due the sum of the two.
   * Using this function we are combining the list of those who liked and retweeted and adding up the tokens due to them that did both
  **/
  function combineMappings(likes, retweets) {
    const combined = {};
  
    // Check if likes is not null
    if (likes !== null) {
      for (let lhandle in likes) {
        if (combined[likes[lhandle]]) {
          combined[likes[lhandle]] += likeAmount;
        } else {
          combined[likes[lhandle]] = likeAmount;
        }
      }
    }
  
    // Check if retweets is not null
    if (retweets !== null) {
      for (let rhandle in retweets) {
        if (combined[retweets[rhandle]]) {
          combined[retweets[rhandle]] += retweetAmount;
        } else {
          combined[retweets[rhandle]] = retweetAmount;
        }
      }
    }

    console.log('combined', combined);
  
    return combined;
  }
  
  //checks if you have enough tokens
  useEffect(() => {
    async function fetchData() {
      const combinedMappings = combineMappings(likes, retweets);
      setCombinedMappings(combinedMappings);

      setWallet(await checkIfWalletIsConnected());
      if(tokenInput) {
        const _balance = await checkBalances(tokenAddress)
        setBalance(_balance);
        const totalTokensNeeded = Object.values(combinedMappings).reduce((a, b) => a + b, 0);
  
        if (totalTokensNeeded > _balance) {
          setTransferDisabled(true);
        } else {
          setTransferDisabled(false);
        }
      }
    }
    fetchData();
  }, [isConnected, tokenInput, tokenAddress, likes, retweets]);

  return (
    <div className='retweet-lookup-master-container'>
      <p> <b> Connect your wallet: </b> </p>
      <ConnectWalletButton setIsConnected={setIsConnected}/>
      <hr className="section-divider" />
      <Input 
        label='Token address:' 
        placeholder='0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174' 
        onChange={(e) => {
          setTokenAddress(e.target.value); 
          setTokenInput(e.target.value !== '');}}/>
      <p>Token balance:</p>
      {tokenInput ? balance : 'Please input token'}
      <hr className="section-divider" />
      <p>Total transfer for likes: {likeAmount * Object.keys(likes).length} </p>
      <p>Total transfer for retweets: {retweetAmount * Object.keys(retweets).length} </p>
      <br />
      <TransferButton tokenAddress={tokenAddress} mapping={combinedMappings} disabled={transferDisabled} setDisabled={setTransferDisabled}/>
    </div>
  );
};
