import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { ActionButton } from '../../components/ActionButton/component';
import { LinkButton } from '../../components/LinkButton/component';

export function Home() {
  const navigate = useNavigate();

  return (
    <>
      <div className="descriptionBox">
        Leverage Tokens in Web2
      </div>
      <div className="descriptionBox2">
        <p>
          Automatically give away tokens and NFTs to people that interact with your Twitter campaigns to 10x likes, retweets, and impressions.
        </p>
        <br />
        <p>
          Link your Twitter handle and EVM address to specify where you want your rewards to go.
        </p>
      </div>
      <div className="navigation">
        <p className='title'>Campaigns</p>
          <ActionButton onClick={() => {navigate('/campaign')} } buttonText={'> Launch'} />  
        <br />
        <p className='title'>Manage Identities</p>
          <ActionButton onClick={() => {navigate('/twitter')} } buttonText={'> Create Link'} />
          <ActionButton onClick={() => {navigate('/view')} } buttonText={'> Manage Links'} />
        <br />
        <p className='title'>Questions</p>
          <LinkButton url="https://punia.notion.site/Token-Automation-Info-3eaa2b01496c4194bfd56b20d22bb92e" buttonText="> Learn More" />  
      </div>
    </>
  );
};
