import React from 'react';
import './styles.css';

export const Input = ({ label, placeholder, value, onChange, type }) => {
  return (
    <div className='input-container'>
      <label>{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      ></input>
    </div>
  );
};
