import React, { useEffect } from 'react';
import { SignIn, useSession } from '@clerk/clerk-react';

export function TwitterSignIn({ setTwitterHandle }) {
  const { session } = useSession();

  useEffect(() => {
    const signOutIfSignedIn = async () => {
      if (session && session.user) {
        console.log("User is signed in, attempting to sign out");
        await session.end();
        if (session.status === 'ended') {
          console.log("User is signed out");
        } else {
          console.log("Failed to sign out the user");
        }
      }
    };
  
    signOutIfSignedIn();
  }, [session])

  return <SignIn afterSignInUrl="/wallet" />;
}