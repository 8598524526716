import React from 'react';
import { ActionButton } from '../../components/ActionButton/component';
import { LinkButton } from '../../components/LinkButton/component';
import { useNavigate } from 'react-router-dom';

export function Link({setPage}) {

  const navigate = useNavigate();

  //Currently this page is not being used

  return (
    <>
    <div className="descriptionBox">
      Link your Twitter handle to your public address to receive free tokens and NFTs.
    </div><div className="descriptionBox2">
        <p>
          Your favorite brands, businesses, communities, and creators want to reward you for interacting with their Tweets.
        </p>
        <p>
          Linking your Twitter handle to your public address lets them do so.
        </p>
        <p>
          Automatically.
        </p>
      </div><div className="navigation">
        <ActionButton onClick={() => {navigate('/twitter')} } buttonText={'> Link'} />
        <LinkButton url="https://punia.notion.site/Token-Automation-Info-3eaa2b01496c4194bfd56b20d22bb92e" buttonText="> Learn More" />
      </div>
    </>
  );
};