import React, { useState } from 'react';
import { Input } from '../Input'; 
import { InteractionLookup } from '../InteractionLookup/component';
import './styles.css';

export const TweetInteraction = () => {
  const [url, setUrl] = useState('');
  const [likeTokenAmount, setLikeTokenAmount] = useState(0);
  const [retweetTokenAmount, setRetweetTokenAmount] = useState(0);

  
  return (
    <div className='tweet-interactions-lookup-master-container'>
      <Input 
        label='Tweet URL' 
        placeholder='https://twitter.com/user/status/1234567890'
        onChange={(e) => setUrl(e.target.value)}
      />
      <Input
        label='Rewards For Likes'
        placeholder={0}
        type='number'
        onChange={(e) => setLikeTokenAmount(parseFloat(e.target.value))}
      />
      <Input
        label='Rewards For Retweets'
        placeholder={0}
        type='number'
        onChange={(e) => setRetweetTokenAmount(parseFloat(e.target.value))}
      />

      <InteractionLookup url={url} likeTokenAmount={likeTokenAmount} retweetTokenAmount={retweetTokenAmount} />

    </div>
  );
};
