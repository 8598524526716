import React from 'react';
import { TweetInteraction } from '../../components/TweetInteraction/component';

export function Campaign() {

  return (
    <>
    <div className="descriptionBox">
            [Beta] Execute Your Campaigns
        </div>
        <div className="descriptionBox2">
            <p>
              Use this interface to send tokens to your fans and customers that have interacted with a Tweet you have a campaign for.
            </p>
            <p>
              Confused about how this works? Check out the "Learn More" link on the Landing page or ask questions in our <a href='https://t.me/+Z0KxvQRfsnpmNTNh' target="_blank" rel="noopener noreferrer" className='clickable-text'>Telegram group</a>.
            </p>
        </div>
      <hr className="section-divider" />
      <TweetInteraction />
    </>
  );
};
